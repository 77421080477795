.section {
  position: relative;
  background-color: $brand-color;
  font-family: $primary-font;
  z-index: 1;
  scroll-snap-type: y mandatory;

  transform: translateY(-5rem);
  opacity: 0.1;
  transition: 0.6s ease-out;

  &:first-of-type {
    transform: translateY(0);

    opacity: 0.3;
    transition: 0.4s ease-in-out;
  }

  &-title {
    padding-bottom: 2rem;
    text-align: center;

    font-size: 1.6rem;

    font-weight: 300;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: $accent-font;
  }

  &-subtitle {
    font-size: 1rem;
    font-weight: 400;
    opacity: 50%;
    text-align: center;
  }

  p {
    a {
      color: $brand-color_light;
    }
  }

  &.show {
    transform: translateY(0);
    opacity: 1;
  }

  &:first-of-type {
    &.show {
      opacity: 1;
    }
  }
}

#introduction-section {
  position: relative;
  height: calc(100vh - 5rem);
  overflow: hidden;

  .wrapper {
    background: linear-gradient(
      78.35deg,
      hsla(0, 100%, 74%, 0.56) 14.23%,
      hsl(248, 87%, 44%) 121.44%
    );
  }

  .container {
    padding: 0 2rem;
    display: grid;
    align-items: center;
    height: 100%;
    position: relative;
  }

  .grid-2 {
    grid-template-columns: 480px 1fr;
    height: 100%;
    align-items: center;
    gap: 2rem;

    .talk {
      position: relative;
      align-items: start;
      transform-origin: left;
      transform: scale(1.2);
      z-index: 1;
      border-radius: 2rem;

      button {
        font-size: 0.9rem;

        padding: 1rem 2rem;
        overflow: visible;

        &:after {
          border-radius: 2rem;
        }
      }
    }

    p {
      font-size: 80%;
      letter-spacing: 0.1px;
      padding-left: 0.25rem;
      line-height: 1.2;
    }

    .attribute {
      font-size: 0.6rem;
      margin-bottom: 0;
      color: $white-trans;
    }

    .illustration {
      margin-top: 3rem;
      transform: translateX(5rem);

      img {
        transform: scale(1.5);
      }
    }
  }

  h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;

    span {
      display: block;
    }
  }

  .absolute {
    bottom: -4rem;
    left: 25%;
    height: 180px;
    width: auto;
  }

  @media (max-width: 580px) {
    height: calc((100vh - 9.88rem) + 16rem);

    *::before {
      position: relative;
    }

    .grid-2 {
      .talk {
        background-color: rgba($color: $brand-color_dark, $alpha: 0.3);
        backdrop-filter: blur(12px);
        padding: 0.85rem 1rem 1.65rem 1rem;

        .moreInfo {
          font-size: 1rem;
          max-width: 80%;
          margin: auto;
        }
      }
    }

    h1 {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-family: $accent-font;
      font-weight: 300;
      letter-spacing: 3px;

      span {
        display: inline;
      }
    }

    .container {
      position: relative;
    }

    .absolute {
      display: none;
    }

    .grid-2 {
      grid-template-rows: 100%;
      grid-template-columns: 100%;
      gap: 0;
      margin-top: 0;
      padding: 0;

      .illustration {
        position: absolute;
        top: -2rem;
        left: 52%;
        width: 100%;
        height: calc(100vh - 10.5rem);

        z-index: 1;
        overflow: hidden;
        transform: translateX(-52%);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.4);
          padding-left: 1rem;
        }
      }

      .talk {
        position: relative;
        z-index: 2;
        margin: 0;
        order: 2;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        border-radius: unset;
        position: absolute;
        bottom: 0;
        text-align: center;
        justify-content: center;
        align-items: center;

        button {
          padding: 1rem 4rem;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    height: calc((100vh - 9.88rem) );

    .grid-2 {
      .illustration {
        margin-top: 10.8rem;
        transform: translateX(16.5rem);

        img {
          transform: scale(2.15);
        }
      }
    }
  }
}

#about-section {
  text-align: center;
  vertical-align: middle;

  p {
    max-width: 720px;
    margin: auto;
    color: $white-trans;
  }

  @media (max-width: 580px) {
    text-align: justify;
  }
}

#beginner-section {
}

#features-section {
  text-align: center;
  background-image: url("/images/gradients/gra-1.png"),
    url("/images/gradients/gra-2.png");
  background-repeat: no-repeat;
  background-size: 65% auto;
  background-repeat: no-repeat;
  background-position: bottom left, bottom right;

  @media (max-width: 580px) {
    .container {
      padding: 2rem 1rem;
    }
    .cards {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
    }
  }
}

#join-community-section {
  .wrapper {
    background: linear-gradient(
      78.35deg,
      hsla(0, 100%, 74%, 0.56) 14.23%,
      hsl(248, 87%, 44%) 121.44%
    );
  }

  p {
    max-width: 720px;
    margin: auto;
    text-align: center;

    &:nth-of-type(2) {
      color: $white-trans;
    }
  }
}

#presale-section {
  .text-accent {
    color: $white-trans;
  }

  h6 {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}

#presale-progress-section {
  @media (max-width: 580px) {
    padding: 2rem;
    flex-direction: column;
    justify-content: space-around;
  }
  h6 {
    background: -webkit-linear-gradient(90deg, #ce4da4 0%, #7353e5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
  }

  box-shadow: 0 0 10px
    linear-gradient(
      90deg,
      rgba(138, 121, 255, 0.8) 0%,
      rgba(206, 77, 164, 0.8) 100%
    );

  .pre-sell {
    padding: 0 2rem;
  }
}

#whitepaper-section {
  .whitepaper-inner {
    position: relative;
    background-color: $brand-color_dark;
    padding: 2rem;
    overflow: hidden;

    border-radius: 2rem;

    h2 {
      font-family: $accent-font;
      font-weight: 300;
      font-size: 1.6rem;

      @media (max-width: 580px) {
        font-size: 1.5rem;
      }
    }
    p {
      text-align: justify;

      @media (max-width: 580px) {
        text-align: left;
        color: $white-trans;
      }
    }

    button {
      display: flex;
      align-self: start;
    }

    .image {
      height: 300px;
      width: 300px;

      @media (max-width: 580px) {
        width: 200px;
        height: 200px;
      }

      border-radius: 50%;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      background-color: $accent-color;

      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .absolute {
      position: absolute;
      height: 5rem;
      bottom: -2rem;
      left: 40%;
    }
  }

  @media (max-width: 580px) {
    text-align: center;

    .whitepaper-inner {
      .absolute {
        bottom: 0;
        left: 60%;
        right: 10%;
      }

      button {
        align-self: center;
      }
    }
  }
}

#roadmap-section {
  .inner-wrapper {
    padding: 0.5rem 2rem;
  }

  .roadmap-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
