.cards {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.tokenomics-grid {
  display: grid;
  grid-template-columns: 100%;
  gap: 2rem;

  font-family: $accent-font;

  @media (min-width: 580px) {
    grid-template-columns: 1fr 2fr;
  }

  .card {
    align-items: start;
    justify-items: center;
    word-break: break-all;
  }

  .grid-item {
    width: 100%;
    align-content: space-between;
    gap: 1rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .flex {
      display: grid;
      width: 100%;
      height: 100%;
      align-content: space-between;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 1rem;

      color: $white-trans;

      &-item {
        width: 100%;
        padding: 1rem;
        height: 100%;

        word-wrap: break-word;
        text-align: left;
        justify-self: start;
      }
    }

    h4 {
      text-align: center;
      font-size: 1.3rem;
      font-family: $accent-font;
      font-weight: 300;
    }

    p {
      margin-bottom: 0;
    }

    .tokendistribution,
    .tokeninformation {
      .before,
      .after {
        position: absolute;
        height: 0.5rem;
        width: 1.8rem;
        border-radius: 2rem;
      }

      .before {
        top: 0.5rem;
        left: 0.5rem;
        background: #726c86;
      }

      .after {
        bottom: 0.5rem;
        right: 0.5rem;
        background: $gradient;
      }
    }

    .tokendistribution {
      background: rgba(80, 73, 106, 0.2);
      position: relative;
      p {
        padding-top: 0.5rem;
      }
      .after {
        background: #c652ae;
      }
    }

    .tokeninformation {
      background: transparent;
      padding: 0;

      > p {
        margin-bottom: 0.25rem;
        font-size: 0.9rem;
      }

      .card {
        position: relative;
        background: rgba(80, 73, 106, 0.2);
        overflow: hidden;
        display: flex;
        padding: 2rem 1rem;

        p {
          padding-top: 0.5rem;
        }
      }
    }
  }
}
