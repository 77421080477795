.splashScreen {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  // gap: 2rem;

  font-family: "Barlow", sans-serif;

  background-color: $brand-color;

  opacity: 0;
  z-index: -1;

  transform: translateY(-50%);
  transition-delay: 0.8s;
  transition: 1s ease-in-out;

  > * {
    visibility: hidden;
    transition: 0.7s ease-in-out;
  transition-delay: 0.3s;

  }

  &.splash {
    z-index: 10000;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.8s;
    transition: 1s ease-in-out;

    > * {
      visibility: visible;
      transition: 0.7s ease-in-out;
  transition-delay: 0.3s;

    }
  }

  .brand {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    font-size: 1.05rem;
    letter-spacing: 1px;
    font-family: $accent-font;
    color: rgba($color: $white, $alpha: 0.8);

    img {
      height: 6rem;
      width: auto;
      margin-right: 0;
    }
  }

  .info {
    position: absolute;
    bottom: 1rem;
    font-size: 0.6rem;
    text-transform: uppercase;
    color: rgba($color: #fff, $alpha: 0.5);
  }

  @media (min-width: 580px) {
    display: none;
  }
}
