@mixin card($align: start) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  gap: 1rem;
  padding: 1.8rem;
  word-break: break-word;

  width: 100%;
  height: auto;

  border-radius: 0.5rem;

  .title {
    font-size: 1.35rem;
    font-family: $accent-font !important;
    font-weight: 300;
  }

  @content;

  @media (max-width: 580px) {
    padding: 0.9rem;

    font-size: 1;
  }

  p.content {
    text-align: left;
  }
}

.FeatureCard,
.RoadMapCard {
  @include card($align: center);

  .image {
    height: 5rem;
    width: 5rem;
    display: grid;
    place-items: center;

    border-radius: 0.5rem;
  }

  p.content {
    color: $white_trans;
    line-height: 1.2;
  }
}

.FeatureCard {
  background-color: rgba($color: $brand-color_dark, $alpha: 0.7);
}

.RoadMapCard {
  background-color: $brand-color_dark;

  .image {
    background-color: $white-trans;
    font-size: 2rem;
    font-weight: 700;

    height: 4rem;
    width: 4rem;
  }
}

.DownloadCard {
  @include card($align: center);
  background: linear-gradient(180deg, #ce4da4 0%, #9075f7 100%);
  width: 100%;

  img {
    height: 8rem;
    width: 8rem;
    object-fit: contain;
  }
}

.GuideContent {
  display: grid;
  grid-gap: 2rem;
  justify-content: start;

  &:first-of-type {
    grid-template-columns: 2fr 1fr;
    margin: 4rem 0;

    img {
      height: 9rem;
      width: 9rem;
    }
  }

  .title {
    font-size: 1.5rem;
    font-family: $accent-font;
    font-weight: 300;
  }

  &:last-of-type {
    grid-template-columns: 1fr 2fr;
  }

  h4 {
    font-size: 1.2rem;
    font-weight: 500;
  }

  p {
    color: $white-trans;
  }

  .button-group {
    gap: 1rem;
  }

  &Info {
    gap: 1rem;
  }
}

.social-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.social-card {
  font-size: 4rem;
  height: 6rem;
  width: 6rem;
  border-radius: 1rem;
  background-color: #222;
  color: $white;
  overflow: hidden;
  display: grid;
  place-items: center;
  transition: 0.3s ease-in-out;
  transform: scale(1);

  &:hover {
    transform: scale(0.85);
  }
}

/* ----- Media Query ----- */

@media (max-width: 580px) {
  .GuideContent {
    order: 2;
    gap: 1rem;
    grid-template-columns: 100% !important;

    .button-group {
      justify-content: space-between;
    }
  }

  .GuideContentInfo {
    gap: 1rem;
    order: 1;

    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .DownloadCard {
    height: 180px;
    max-width: 80%;
    margin: auto;
    display: grid;
    place-items: center;
  }
}
