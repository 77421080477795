.brand {
    white-space: nowrap;
    font-size: 0.8rem;
    text-transform: uppercase;
    gap: 0.5rem;
    font-weight: 700;

    span {
      display: block;
    }

    img {
      height: 3rem;
    }
  }