.CountDownTimer {
  position: relative;
  background-color: $brand-color_dark;
  padding: 4rem 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px
    linear-gradient(
      90deg,
      rgba(138, 121, 255, 0.8) 0%,
      rgba(206, 77, 164, 0.8) 100%
    );
  box-shadow: 0 0 15px rgba(206, 77, 164, 0.3);

  align-items: center;

  &::before {
    width: 102%;
    height: 105%;
    top: 50%;
    left: 50%;
    z-index: -1;
    border-radius: inherit;
    transform: translate(-50%, -50%);
    background: linear-gradient(90deg, #7353e5 0%, #ce4da4 50%, #ce4da4 100%);
    opacity: 0.2;
    filter: blur(8px);
  }

  > * {
    flex: 1 1 100%;
  }

  .box {
    padding: 1.5rem;
    display: flex;

    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 150px;


    &-inner {
      display: flex;
      flex-direction: column;
      justify-items: center;
      gap: 1rem;
      align-content: center;
    }

    &:not(:last-of-type) {
      border-right: 1px solid $white-trans;
    }
  }

  .title {
    font-size: 1.35rem;
    letter-spacing: 1px;
    font-family: $accent-font;
    font-weight: 300;
    text-transform: uppercase;
  }

  .price,
  .countdown {
    background: -webkit-linear-gradient(90deg, #ce4da4 0%, #7353e5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
  }

  .price {
    font-size: 1.35rem;
  }

  .countdown {
    font-size: 1.85rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .timing {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .label {
        font-size: 0.6rem !important;
      }
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 580px) {
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    .box {
      &:not(:last-of-type) {
        border-right: none;

        border-bottom: 1px solid $white;
      }
    }
  }
}
