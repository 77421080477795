$gradient: linear-gradient(
  264.5deg,
  rgb(99, 46, 166) 28.15%,
  rgb(115, 83, 229) 77.79%
);
$brand-color: #261e35;
$brand-color_dark: #1d1a27;
$brand-color_light: #8a79ff;

$white: #fff;

$white_trans: rgba(255, 255, 255, 0.5);

$accent-color: #ce4da4;

$default-text-color: $white;
