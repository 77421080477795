.presale-bar {
  background: #1d1a27;
  height: 50vh;
  border-radius: 10px;
  margin-top: 5rem;
  box-shadow: 0 0 12px rgba($color: $brand-color_light, $alpha: 0.5),
    0 0 20px rgba($color: $accent-color, $alpha: 0.5);
  text-align: center;
  font-family: "Poppins", serif;
}

.pre-sale-pricing-bar h5 {
  font-size: 2rem;
}

.pre-sale-pricing-bar h6 {
  font-size: 1.4rem;
}

.timer {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}
