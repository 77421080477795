* {
  font-family: inherit;
  user-select: none;

  &,
  &::before,
  &:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  &::before,
  &::after {
    position: absolute;
    content: "";
  }
}

html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  scroll-snap-align: top;
  scroll-padding-top: 5rem;
  font-size: 100%;

  @media (max-width: 580px) {
    font-size: 85%;
  }
}

body {
  padding-top: 5rem;
  height: 100%;
  width: 100%;
  font-family: $primary-font;
  color: $default-text-color;
  font-size: 15px;
  background: $brand-color;
  overflow-x: hidden;

  &.ov-hidden {
    overflow: hidden;
  }
}

ul {
  list-style: none;
}
p {
  white-space: normal;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

img,
video,
iframe {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
