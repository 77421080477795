.wrapper {
  height: 100%;
  width: 100%;
}

.inner-wrapper {
  width: 100%;
  max-width: 960px;
  margin: auto;
  padding: 2rem;
}
