$positions: ("relative", "absolute", "fixed", "sticky");

@each $position in $positions {
  .#{$position} {
    position: #{$position};
  }
}

$axes: ("top", "right", "left", "bottom");

@each $axis in $axes {
  .#{$axis} {
    #{$axis}: 0;
  }
}
