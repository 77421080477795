header {
  background-color: $brand-color_dark;
  white-space: nowrap;
  font-family: $accent-font;
  height: auto;
  width: 100%;
  position: fixed;
  height: 5rem;
  top: 0;
  left: 0;
  z-index: 1000;

  .container {
    padding: 1rem 2rem;
  }
}
