.menu-toggle {
  font-size: 2rem;
  cursor: pointer;
  position: relative;
  display: grid;
  place-items: center;

  .open,
  .close {
    position: absolute;
    transform: scale(0);

    &.active{
      transform: scale(1);
    }
  }

  &:hover {
    color: $brand-color_light;
  }

  @media (min-width: 580px) {
    display: none;
  }
}
