/* ----- MARGIN ----- */
@for $i from 0 to 6 {
  /* margin x#{$i} */
  .m-#{$i} {
    margin: calc(var(--size) * #{$i});
  }
  .ml-#{$i} {
    margin-left: calc(var(--size) * #{$i});
  }
  .mr-#{$i} {
    margin-right: calc(var(--size) * #{$i});
  }
  .mt-#{$i} {
    margin-top: calc(var(--size) * #{$i});
  }
  .mb-#{$i} {
    margin-bottom: calc(var(--size) * #{$i});
  }
  .mx-#{$i} {
    margin: 0 calc(var(--size) * #{$i});
  }
  .my-#{$i} {
    margin: calc(var(--size) * #{$i}) 0;
  }
}

.m-unset {
  margin: 0;
}

.m-auto {
  margin: auto;
}
