.footer {
  position: relative;
  z-index: 100;
  padding: 1rem;
  background-color: hsl(261, 28%, 16%);
  color: #fff;
  line-height: 1.8;
  border-top: 3px solid rgba($color: $accent-color, $alpha: .2);

  .component {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &-item {
    width: 100%;
  }

  ul {
    width: 100%;
    display: flex;
  }

  &-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ul {
      display: flex;
      flex-direction: column;
      font-size: 0.8rem;

      li a {
        display: flex;
        padding: 0.25rem 0;
      }
    }
  }

  img {
    height: 100%;
    width: auto !important;
    object-fit: contain;
  }

  h4 {
    font-weight: 600;
    color: #fff;
  }

  .copyright {
    color: $white-trans;
    pointer-events: none;

    p {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 580px) {
    .component {
      grid-template-columns: repeat(2, 1fr)
    }

    .footer-item {
      gap: .25rem;

      &:last-of-type{
       grid-column: span 2;
       button{
         align-self: center;
         justify-content: center;
       }
      }
    }

    input {
      width: 100%;
      max-width: 100%;
      min-width: unset;
      margin-bottom: .5rem;
    }

    .copyright {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
