.ProgressBar {
  position: relative;
  $border: 3px;
  width: 100%;
  height: 1rem;
  background-clip: padding-box !important;
  background-color: $brand-color;
  border-radius: 1rem;
  border: $border solid transparent;

  .detect{
    position: absolute;
    inset: 0;
    z-index: 2;
    height: 100%;
    width: 10%;
    border-radius: inherit;
    background: linear-gradient(90deg, #7353e5 0%, #ce4da4 50%, #ce4da4 100%);
  }

  &::before,
  &::after {
    inset: 0;
  }

  // &::after {
  //   z-index: 2;
  //   height: 100%;
  //   width: 10%;
  //   border-radius: inherit;
  //   background: linear-gradient(90deg, #7353e5 0%, #ce4da4 50%, #ce4da4 100%);
  // }

  &::before {
    z-index: -1;
    border-radius: inherit;
    margin: -$border !important;
    background: linear-gradient(90deg, #7353e5 0%, #ce4da4 50%, #ce4da4 100%);
  }
}
