/* ----- Display ----- */

$displays: ("flex", "grid", "block", "inline");

@each $display in $displays {
  .#{$display} {
    display: #{$display};
  }
}

/* ----- Flex and Grid Gap ----- */
@for $i from 1 to 6 {
  .gap-#{$i} {
    gap: calc(var(--size) * #{$i}) !important;
  }
}

/* ----- Flex and Grid Alignment ----- */
.justify-around {
  display: flex;
  justify-content: space-around;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

/* ----- Flex Direction----- */
$flex-direction: ("row", "column");

@each $direction in $flex-direction {
  .flex-#{$direction} {
    flex-direction: #{$direction};
  }
}

/* ----- Flex Utilities ----- */
.flex-wrap {
  flex-wrap: wrap;
}

/* ----- Flex and Grid Gaps ----- */
// .gap-2 {
//   gap: 2rem;
// }

.grid-2 {
  display: grid;
  grid-template-columns: 100%;

  @media (min-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.hide-mobile {
  @media (max-width: 580px) {
    display: none;
  }
}
