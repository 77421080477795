nav {
  width: 100%;
  justify-content: space-between;

  ul {
    height: 100%;
    justify-content: center;
    width: 100%;

    li {
      height: 100%;
    }
    a {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0.5rem;
      font-size: 0.8rem;
      color: $white-trans;

      &:hover {
        color: #fff;
      }
    }
  }

  .button {
    padding: 0.5rem 2rem !important;
    font-size: 0.85rem;
    font-weight: 500;
    display: grid;
    place-items: center;
  }

  @media (max-width: 580px) {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 100;
    padding: 1rem 2rem;
    flex-direction: column;

    background-color: $brand-color_dark;

    transform: scaleY(0);
    transform-origin: top;
    transform: perspective(3d);
    transition: 0.3s ease-in-out transform;

    ul {
      flex-direction: column;

      li {
        a {
          font-size: 1rem;
          padding: 1rem;
        }
      }
    }

    &.active {
      transform: scaleY(1);
    }

    button {
      margin-top: 1rem;
      width: 100%;
    }
  }

  .button {
    font-size: 1rem;
    padding: 1rem 2rem !important;
    background-image: linear-gradient(
      90deg,
      #7353e5 0%,
      #ce4da4 50%,
      #ce4da4 100%
    ) !important;

    transition: 0.3s ease-in-out;

    &:hover {
      background: transparent !important;
    }

    &::after {
      display: none;
    }
  }
}
