/* ----- PADDING ----- */
@for $i from 0 to 4 {
  /* Padding x#{$i} */
  .p-#{$i} {
    padding: calc(var(--size) * #{$i});
  }
  .pl-#{$i} {
    padding-left: calc(var(--size) * #{$i});
  }
  .pr-#{$i} {
    padding-right: calc(var(--size) * #{$i});
  }
  .pt-#{$i} {
    padding-top: calc(var(--size) * #{$i});
  }
  .pb-#{$i} {
    padding-bottom: calc(var(--size) * #{$i});
  }
  .px_#{$i} {
    padding-left: calc(var(--size) * #{$i});
    padding-right: calc(var(--size) * #{$i});
  }
  .py-#{$i} {
    padding-top: calc(var(--size) * #{$i});
    padding-bottom: calc(var(--size) * #{$i});
  }
}
