.btn {
  font-family: Helvetica;
  position: relative;
  font-weight: 400;
  font-size: 0.85rem;
  border-radius: 1rem;
  padding: 1rem 3.5rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  transition: all 200ms linear;

  border: 1px solid transparent;
  outline: 0;

  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);

  cursor: pointer;
  transition: all 200ms linear;

  white-space: nowrap;
}
